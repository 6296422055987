import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue3";
import { InertiaProgress } from "@inertiajs/progress";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import * as Sentry from "@sentry/vue";
import { plugin as VueTransitions } from "@morev/vue-transitions";
import "@morev/vue-transitions/styles";
import "./icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AppButton from "./Components/Button.vue";

const appName = window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
  setup({ el, app, props, plugin }) {
    const vueApp = createApp({ render: () => h(app, props) });

    Sentry.init({
      app: vueApp,
      dsn: "https://ddb54019b370ecff0b6a24cb22f0bf1e@o4506707509051392.ingest.sentry.io/4506707510296576",
      integrations: [],
    });

    return vueApp
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .component("fa", FontAwesomeIcon)
      .component("AppButton", AppButton)
      .use(
        VueTransitions({
          components: {
            TransitionExpand: "Expand",
          },
        })
      )
      .mount(el);
  },
});

InertiaProgress.init({ color: "#f6cd38" });
