import {library} from "@fortawesome/fontawesome-svg-core";

// SOLID
import {
    faCamera as fasCamera,
    faCaretRight as fasCaretRight,
    faCheck as fasCheck,
    faCheckCircle as fasCheckCircle,
    faCircleNotch as fasCircleNotch,
    faCircleXmark as faCircleXmark,
    faExclamationCircle as fasExclamationCircle,
    faFileExport as fasFileExport,
    faImages as fasImages,
    faMinusCircle as fasMinusCircle,
    faPalette as fasPalette,
    faPenToSquare as fasPenToSquare,
    faPlusCircle as fasPlusCircle,
    faSpinnerThird as fasSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";

// REGULAR
import {
    faArrowUpFromBracket as farArrowUpFromBracket,
    faCheck as farCheck,
    faCircleEllipsis as farCircleEllipsis,
    faClockRotateLeft as farClockRorateLeft,
    faComment as farComment,
    faEllipsis as farEllipsis,
    faEraser as farEraser,
    faMagnifyingGlass as farMagnifyingGlass,
    faPalette as farPalette,
    faPencil as farPencil,
    faPenToSquare as farPenToSquare,
    faRotateLeft as farRotateLeft,
    faRotateRight as farRotateRight,
    faTimes as farTimes,
    faTrashXmark as farTrashXmark,
} from "@fortawesome/pro-regular-svg-icons";

// LIGHT
import {
    faAngleLeft as falAngleLeft,
    faAngleRight as falAngleRight,
    faArrowRight as falArrowRight,
    faBriefcase as falBriefcase,
    faCircle as falCircle,
    faCircleInfo as falCircleInfo,
    faClipboardList as falClipboardList,
    faCog as falCog,
    faComment as falComment,
    faDiamondExclamation as falDiamondExclamation,
    faExclamationCircle as falExclamationCircle,
    faGlobe as faGlobe,
    faPalette as falPalette,
    faPen as faPen,
    faPencil as falPencil,
    faPencilSlash as falPencilSlash,
    faPenToSquare as falPenToSquare,
    faPhone as faPhone,
    faRightFromBracket as falRightFromBracket,
    faSortAlt as falSortAlt,
    faTimes as falTimes,
    faTrashXmark as falTrashXmark,
} from "@fortawesome/pro-light-svg-icons";

// THIN
import {faDiamondExclamation as fatDiamondExclamation, faUsers as fatUsers} from "@fortawesome/pro-thin-svg-icons";

library.add(
    faCircleXmark,
    faPen,
    faPhone,
    faGlobe,
    fasPlusCircle,
    fasMinusCircle,
    falArrowRight,
    falAngleLeft,
    falAngleRight,
    falExclamationCircle,
    fasExclamationCircle,
    fasCheckCircle,
    fasCaretRight,
    farTimes,
    falTimes,
    falCircle,
    farPencil,
    farEraser,
    farClockRorateLeft,
    farRotateLeft,
    farRotateRight,
    farTrashXmark,
    fasPalette,
    farPalette,
    falPalette,
    fasImages,
    fasPenToSquare,
    fasCamera,
    farComment,
    farPenToSquare,
    falComment,
    falTrashXmark,
    falPenToSquare,
    falPencil,
    fasCircleNotch,
    fasSpinnerThird,
    farCheck,
    falBriefcase,
    falCog,
    falRightFromBracket,
    falPencilSlash,
    fatUsers,
    fatDiamondExclamation,
    falCircleInfo,
    falClipboardList,
    falDiamondExclamation,
    fasFileExport,
    farMagnifyingGlass,
    fasCheck,
    farArrowUpFromBracket,
    farCircleEllipsis,
    farEllipsis,
    falSortAlt,
);
