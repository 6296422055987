<script setup>
import { Inertia } from "@inertiajs/inertia";
const props = defineProps({
  type: {
    type: String,
    default: "button",
  },
  href: {
    type: String,
    default: null,
    required: false,
  },
  icon: {
    type: String,
    required: false,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

function clickHandler() {
  if (props.href && !props.disabled) {
    Inertia.visit(props.href);
  }
  if (props.disabled) {
    return false;
  }
}
</script>

<template>
  <button
    :type="type != 'text' ? type : 'text'"
    :class="[type == 'text' ? 'text-link' : 'button-link', props.disabled ? 'disabled' : '']"
    :disabled="props.disabled"
    @click="clickHandler">
    <fa v-if="props.icon" :icon="props.icon" class="pre-icon" />
    <slot />
  </button>
</template>

<style>
.app-button .svg-inline--fa {
  @apply text-xl mr-2 -ml-1;
}
</style>
